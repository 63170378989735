import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'

import 'bootstrap';

import './Document/Fragment/Layout/Basket';
import './Document/Fragment/Layout/Footer';
import './Document/Shop/Product';
import './Content/HeroSlider';

window.Alpine = Alpine;

Alpine.plugin(collapse);
Alpine.start();
