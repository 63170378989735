import Alpine from 'alpinejs';
import {Offcanvas} from 'bootstrap'
import Swal from 'sweetalert2'

Alpine.store('basket', {
    total: '',
    products: [],

    init() {
        return fetch('/shop/basket/get')
            .then(res => res.json())
            .then((d) => this.setData(d));
    },

    setData(data) {
        Object.assign(this, data);
    }
});

Alpine.data('basket', (index) => ({
    quantity: 1,

    updateQuantity(index, product) {
        fetch('/shop/basket/updateQuantity', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                index: index,
                quantity: product.quantity
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.products.length === 0 &&
                    document.body.classList.contains('checkout')) {
                    location.reload();
                }

                if (data.error) {
                    Swal.fire({
                        icon: 'error',
                        text: data.error
                    });
                } else {
                    Alpine.store('basket').setData(data);
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Please try again later.'
                });
            });
    },
    remove(index) {
        fetch('/shop/basket/remove', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                index: index
            })
        })
            .then(res => res.json())
            .then(data => {
                Alpine.store('basket').setData(data);
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Please try again later.'
                });
            });
    },
    add(productNode) {
        fetch('/shop/basket/add', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                productNode: productNode,
                quantity: this.quantity
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.error) {
                    Swal.fire({
                        icon: 'error',
                        text: data.error
                    });
                } else {
                    Alpine.store('basket').setData(data);
                    this.showBasket();
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Please try again later.'
                });
            });
    },
    showBasket() {
        const basketContainer = new Offcanvas(document.getElementById('basket'));
        basketContainer.show();
    }
}));
