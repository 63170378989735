import Swiper, {Autoplay} from "swiper";

if (document.querySelector('.product-swiper')) {
    const thumbsSwiper = new Swiper('.product-swiper-thumbs', {
        loop: true,
        centeredSlides: true,
        slidesPerView: 4,
        direction: 'vertical',
    });

    const mainSwiper = new Swiper('.product-swiper-main', {
        loop: true,
        centeredSlides: false,
        freeMode: false,
        modules: [Autoplay],
        thumbs: {
            swiper: thumbsSwiper
        },
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
    });

    new ResizeObserver(([swiper]) => {
        const direction = swiper.contentRect.width < 576 ? 'horizontal' : 'vertical';
        if (swiper.target.classList.contains(direction)) return;
        swiper.target.classList.remove('vertical', 'horizontal');
        swiper.target.classList.add(direction);
        thumbsSwiper.changeDirection(direction);
    }).observe(document.querySelector('.product-swiper'));

    document.querySelectorAll('.product-swiper-thumbs .swiper-slide').forEach((slide, index) => {
        slide.addEventListener('click', (e) => {
            if (!e.currentTarget.classList.contains('swiper-slide-active')) {
                if (!e.currentTarget.previousElementSibling) {
                    mainSwiper.slidePrev();
                }

                e.currentTarget.previousElementSibling?.classList.contains('swiper-slide-active') ? mainSwiper.slideNext() : mainSwiper.slidePrev();
            }
        });
    });

    const speed = undefined; // use default

    mainSwiper.on('slidePrevTransitionStart', () => thumbsSwiper.slidePrev(speed, false));
    mainSwiper.on('slideNextTransitionStart', () => thumbsSwiper.slideNext(speed, false));
    thumbsSwiper.on('activeIndexChange', ({realIndex}) => mainSwiper.slideToLoop(realIndex, speed, false));
}