import Alpine from 'alpinejs';
import Swiper, {Autoplay} from "swiper";

Alpine.data('heroSlider', (element) => ({
    init() {
        const rootElement = this.$root;
        const progressBarElement = rootElement.querySelector('.progress-bar');

        new Swiper(rootElement.querySelector('.swiper-container'), {
            loop: true,
            centeredSlides: false,
            freeMode: false,
            modules: [Autoplay],
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
            on: {
                autoplayTimeLeft(s, time, progress) {
                    const percentage = ((4000 - time) / 4000) * 100;
                    progressBarElement.style.width = percentage.toFixed(1) + '%';
                }
            }
        });
    }
}));